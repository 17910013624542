
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ClientList from "@/components/widgets/client/Client-User-List-Widget.vue";
import NewClientUserModal from "@/components/modals/forms/NewClientUserModal.vue";

export default defineComponent({
  name: "client-client-poweruser-list",
  components: { ClientList, NewClientUserModal },
  setup() {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });

    return {
      currentUser,
      currentClient,
    };
  },
});
